import React from 'react';
import {Box} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Event from '../../../action/event';
import Selector from '../../../selector';
import CustomButton from '../../../tenants/segmenta/component/CustomButton';

const useStyles = makeStyles(theme => ({
    nextButton: {
        width: '100%',
        radius: theme.shape.borderRadius,
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#000000',
        },
        textTransform: 'none',
    },
}));

interface BookLocationNavigateNextActionProps {
}

const BookLocationNavigateNextAction = (props: BookLocationNavigateNextActionProps) => {
    const tenantName = process.env.REACT_APP_TENANT;

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const nextEnabled = useSelector(Selector.BookingCreation.nextEnabled);
    const step = useSelector(Selector.BookingCreation.step);

    let showBookButton = step === 3;

    if (tenantName === 'segmenta' && step === 4) {
        showBookButton = true;
    }

    if (tenantName === 'vonovia') {
        showBookButton = step === 4;
    }

    const handleNextClick = () => {
        dispatch(Event.BookingCreation.navigatedToNextStep({}));
    };


    return (
        <Box>
            <CustomButton
                key={`${!nextEnabled}`}
                className={classes.nextButton}
                variant={nextEnabled ? 'outlined' : 'contained'}
                // color='primary'
                disabled={!nextEnabled}
                children={t(showBookButton ? 'book_location.confirmation.order_now' : 'general.next')}
                onClick={handleNextClick}
                isButtonDark={true}
            />
        </Box>
    );
};

export default BookLocationNavigateNextAction;
